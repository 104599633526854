export default [
  "N/A",
  "Corporate Manager",
  "Diamond Club",
  "Director",
  "Key Account Manager",
  "Leadership Team",
  "O&O District Manager",
  "O&O Store Manager",
  "Regional Sales Manager",
  "Sales Trainer",
  "Support Staff",
  "Territory Manager",
];
