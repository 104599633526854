<template>
  <Page
    layout="ResponsiveLayout"
    class="ondemand"
    poster="https://delivery.eventcdn.net/events/638/1a123b35-cd82-4fcd-a519-e1e9a7808331/5045Web_1920__4.png"
  >
    <v-container fluid>
      <v-row>
        <v-col cols="12" class="d-flex flex-row align-center mt-5">
          <v-img
            src="https://delivery.eventcdn.net/events/638/b5301970-648f-4f59-b2e3-bcb279e91f0e/5011Pandora_Monogram_RRXOqFr.png"
            class="logo ml-0 ml-lg-16 mr-5"
          >
          </v-img>
          <div class="pt-6">
            <h3 class="h-date">MARCH 28th-30th</h3>
            <h1 class="h-title">SPRING 2022 CONFERENCE</h1>
          </div>
        </v-col>
      </v-row>
      <v-row class="pt-16 pl-lg-0 mt-16 mt-lg-0">
        <v-col cols="12" lg="9" class="mb-14">
          <h3 class="h-speakers pl-lg-16 pb-3">Event Archive</h3>
          <div class="pt-2 ml-lg-16 mb-16 px-5 pt-10 ondemand-bg">
            <v-row v-for="agenda in computedAgendas" :key="agenda.id">
              <template v-if="agenda.sessions.length > 0">
                <v-col cols="12" lg="12" xl="2">
                  <div class="d-flex flex-row">
                    <span
                      class="py-3 mt-1 px-6"
                      style="background-color: #572171; font-size: 1.3rem"
                      >{{ agenda.weekday }}</span
                    >
                    <div
                      class="px-3 mt-1 text-center"
                      style="background-color: #ffffff; color: #572171"
                    >
                      <p class="mt-2" style="line-height: 1">
                        {{ agenda.day }}
                      </p>
                      <p class="mt-n5 mb-0 font-weight-regular">
                        {{ agenda.month }}
                      </p>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" lg="12" xl="10">
                  <v-row v-for="session in agenda.sessions" :key="session.id">
                    <v-col cols="12" md="6">
                      <v-responsive :aspect-ratio="16 / 9">
                        <video
                          :src="session.ondemand_video_url"
                          :poster="session.ondemand_splash_url"
                          controls
                          allowfullScreen
                          playsinline
                          style="height: 100%; width: 100%"
                          controlslist="nodownload"
                          @contextmenu.prevent=""
                        ></video>
                      </v-responsive>
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                      class="pl-3 pl-md-6"
                      style="margin-top: 3px"
                    >
                      <span
                        class="py-0 px-6"
                        style="background-color: #572171; font-size: 1.3rem"
                        >ON DEMAND</span
                      >
                      <h1
                        class="font-weight-regular pt-5"
                        style="font-size: 1.9rem"
                      >
                        {{ session.name }}
                      </h1>
                      <h3 class="font-weight-light pb-6 pb-xl-0">
                        {{ getSpeakerNames(session) }}
                      </h3>
                    </v-col>
                  </v-row>
                </v-col>
              </template>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </Page>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapState(["agendas"]),
    ...mapGetters(["me"]),

    computedAgendas() {
      if (!this.agendas || this.agendas.length === 0) return [];

      const days = [1, 2, 3];

      const agendas = [];

      days.forEach((day) => {
        const agendasForDay = this.agendas.filter(
          (agenda) => agenda.event_day === day
        );

        const agendaData = {
          id: agendasForDay[0].id,
          weekday: agendasForDay[0].weekday,
          day: agendasForDay[0].day,
          month: agendasForDay[0].month,
          sessions: [],
        };

        agendasForDay.forEach((agendaForDay) => {
          agendaData.sessions = agendaData.sessions.concat(
            agendaForDay.sessions
              .filter((session) => !!session.ondemand_video_url)
              .filter((session) => {
                if (this.isAdmin) return true;

                const userGroups = this.me?.groups;
                const sessionGroups = session?.user_groups;

                if (!sessionGroups || sessionGroups?.length === 0) return true;

                if (userGroups) {
                  return sessionGroups.some((sessionGroup) =>
                    userGroups.includes(sessionGroup)
                  );
                }

                return false;
              })
          );
        });

        agendas.push(agendaData);
      });

      return agendas;
    },
  },

  methods: {
    getSpeakerNames(session) {
      const names = session.speakers.map((speaker) => speaker.name);

      return names.join(", ");
    },
  },
};
</script>


<style lang="scss">
/* .livehouse-app.v-application {
  .ondemand {
    
  }
} */
</style>