<template>
	<Page
		v-if="currentAgenda"
		layout="ResponsiveLayout"
		:poster="currentAgenda.background_image_url"
		class="live"
		style="height: 100%"
	>
		<v-container fluid style="height: 100%">
			<v-row>
				<v-col cols="12" class="d-flex flex-row align-center mt-5">
					<v-img
						src="https://delivery.eventcdn.net/events/638/b5301970-648f-4f59-b2e3-bcb279e91f0e/5011Pandora_Monogram_RRXOqFr.png"
						class="logo ml-16 mr-5"
					>
					</v-img>
					<div class="pt-6">
						<h3 class="h-date">MARCH 28th-30th</h3>
						<h1 class="h-title">SPRING 2022 CONFERENCE</h1>
					</div>
				</v-col>
			</v-row>
			<template v-if="hasAccess">
				<v-row>
					<v-col cols="12" lg="9">
						<v-responsive :aspect-ratio="16 / 9" class="pl-lg-16">
							<iframe
								title="Inline Waiting Frame"
								allowfullscreen
								style="height: 100%; width: 100%"
								src="https://streams.eventcdn.net/pandoranam/nam-2022-live"
								frameborder="0"
							>
							</iframe>
						</v-responsive>
					</v-col>
					<v-col cols="12" lg="3" class="pr-0 pl-lg-16">
						<v-navigation-drawer
							class="ml-auto h-agenda"
							height="500"
							width="500"
							:mini-variant="mini"
							permanent
						>
							<v-list-item class="px-2 h-agenda">
								<v-btn
									v-if="show"
									icon
									@click="
										mini = !mini;
										show = !show;
									"
								>
									<v-icon class="pl-1" style="color: white"
										>fas fa-chevron-double-left</v-icon
									>
								</v-btn>
								<v-list-item-title
									class="pl-3"
									style="color: white"
									><h2 style="font-size: 1.2rem">
										Agenda - Day {{ event.day }}
									</h2></v-list-item-title
								>
								<v-btn
									icon
									@click="
										mini = !mini;
										show = !show;
									"
								>
									<v-icon style="color: white"
										>fas fa-chevron-double-right</v-icon
									>
								</v-btn>
							</v-list-item>

							<div
								class="px-4 py-4"
								style="
									overflow-y: visible;
									overflow-x: hidden;
									height: 451px;
								"
							>
								<v-expansion-panels
									class="transparent"
									flat
									dark
									readonly
									:value="agendaMenuValue"
								>
									<template v-for="agenda in computedAgendas">
										<template
											v-if="agenda.sessions.length === 1"
										>
											<!-- Show one session -->
											<v-expansion-panel
												:key="agenda.id"
												class="transparent px-0"
												:class="{ 'pl-2': !show }"
											>
												<v-expansion-panel-header
													class="
														px-0
														py-2
														agenda-menu-h
													"
													:expand-icon="null"
													hide-actions
												>
													<div
														class="
															d-flex
															align-center
														"
														style="gap: 1rem"
													>
														<div
															class="
																rounded-circle
															"
															:style="{
																background:
																	agenda.current
																		? 'white'
																		: agenda.previous
																		? '#FFFFFF62'
																		: 'linear-gradient(180deg, #00014A 0%, #8A82B1 100%)',
																border: agenda.previous
																	? '1px solid #FFFFFF'
																	: null,
																padding:
																	agenda.previous
																		? '10px'
																		: '12px',
															}"
														></div>
														<div
															v-html="
																agenda.title
															"
														></div>
													</div>
												</v-expansion-panel-header>
											</v-expansion-panel>
										</template>

										<template
											v-if="agenda.sessions.length > 1"
										>
											<v-expansion-panel
												:key="agenda.id"
												class="transparent px-0"
											>
												<v-expansion-panel-header
													class="
														px-0
														py-2
														agenda-menu-h
													"
													:class="{ 'pl-2': !show }"
													hide-actions
												>
													<div
														class="
															d-flex
															align-center
														"
														style="gap: 1rem"
													>
														<div
															class="
																rounded-circle
															"
															:style="{
																background:
																	agenda.current
																		? 'white'
																		: agenda.previous
																		? '#FFFFFF62'
																		: 'linear-gradient(180deg, #00014A 0%, #8A82B1 100%)',
																border: agenda.previous
																	? '1px solid #FFFFFF'
																	: null,
																padding:
																	agenda.previous
																		? '10px'
																		: '12px',
															}"
														></div>
														<div
															v-html="
																agenda.title
															"
														></div>
													</div>
												</v-expansion-panel-header>
												<v-expansion-panel-content
													class="pa-0"
												>
													<v-list
														subheader
														class="pa-0 pl-7"
														dense
													>
														<v-list-item
															v-for="session in agenda.sessions"
															:key="session.id"
															class="
																py-0
																agenda-submenu
															"
														>
															<div
																class="
																	d-flex
																	align-center
																"
																style="
																	gap: 1rem;
																"
															>
																<div
																	class="
																		rounded-circle
																	"
																	:style="{
																		background:
																			session.current
																				? 'white'
																				: session.previous
																				? '#FFFFFF62'
																				: 'linear-gradient(180deg, #00014A 0%, #8A82B1 100%)',
																		border: session.previous
																			? '1px solid #FFFFFF'
																			: null,
																		padding:
																			session.previous
																				? '6px'
																				: '8px',
																	}"
																></div>
																{{
																	session.name
																}}
															</div>
														</v-list-item>
													</v-list>
												</v-expansion-panel-content>
											</v-expansion-panel>
										</template>
									</template>
								</v-expansion-panels>
							</div>
						</v-navigation-drawer>

						<v-navigation-drawer
							v-if="currentAgenda.id === 1"
							class="ml-auto h-agenda hide-scroll"
							height="115"
							width="450"
							style="position: relative; top: 120px"
							:mini-variant="false"
							permanent
						>
							<div class="d-flex flex-row pt-1 px-2">
								<v-icon class="pl-2" style="color: white"
									>fas fa-comment-dots</v-icon
								>

								<h2
									class="pl-3"
									style="font-size: 1.2rem; color: white"
								>
									Ask a Question
								</h2>
							</div>
							<v-form @submit.prevent="sendQaMessage">
								<v-text-field
									v-model="qaForm.message"
									class="px-14 ask-a-question-live"
									height="40px"
									solo
									placeholder="Enter your message"
									required
								></v-text-field>
							</v-form>
							<div
								class="
									mt-n6
									mr-14
									text-right
									font-weight-regular
								"
								style="font-size: 0.9rem"
							>
								Press <strong>Enter</strong> to send
							</div>
							<div
								v-if="qaForm.isSuccess"
								class="
									mt-n6
									ml-14
									text-left
									font-weight-regular
								"
								style="font-size: 0.9rem"
							>
								Your message was sent!
							</div>
						</v-navigation-drawer>
					</v-col>
				</v-row>
				<v-row class="pt-16 pl-lg-0 mt-16 mt-lg-0">
					<v-col cols="12" lg="9" class="mb-14">
						<h3 class="h-speakers pl-lg-16 pb-3">About</h3>
						<div class="pt-2 ml-lg-16 mb-16 px-12 pt-10 h-agenda">
							<div class="py-5" style="max-width: 680px">
								<h2 class="pb-3 session-name">
									{{ currentSession.name }}
								</h2>
								<p
									class="session-description"
									v-if="currentSession.description"
								>
									{{ currentSession.description }}
								</p>
							</div>

							<v-row v-if="currentSession.speakers.length !== 0">
								<v-col
									class="d-flex flex-column pl-7"
									xl="3"
									lg="4"
									md="4"
									cols="12"
									v-for="speaker in currentSession.speakers"
									:key="speaker.id"
								>
									<v-list-item-avatar
										class="mr-auto"
										size="110"
									>
										<v-img
											:src="speaker.image"
											:alt="speaker.name"
										></v-img>
									</v-list-item-avatar>
									<div class="">
										<p class="speaker-title pt-4">
											{{ speaker.job_title }}
										</p>
										<p class="speaker-name mt-n6">
											{{ speaker.name }}
										</p>
										<div
											v-html="speaker.description"
											class="
												speaker-description
												mt-n3
												mb-4
												line-clamp
											"
										></div>
									</div>
									<v-menu top offset-y>
										<template
											v-slot:activator="{ on, attrs }"
										>
											<v-btn
												v-bind="attrs"
												v-on="on"
												tile
												color="#090071"
												class="
													mr-auto
													mb-9
													px-12
													text-capitalize
												"
												style="
													color: #ffffff;
													border: 1px solid #ffffff;
												"
												>Read More</v-btn
											>
										</template>
										<v-card
											class="pa-3 pb-5 speakers-popup-bg"
											style="
												max-width: 350px;
												overflow-y: auto;
											"
										>
											<p class="text-right">
												<v-btn
													icon
													@click="menu = false"
													dark
													><v-icon
														>fa fa-times</v-icon
													></v-btn
												>
											</p>
											<p class="text-center">
												<v-list-item-avatar
													class="mr-auto"
													size="110"
												>
													<v-img
														:src="speaker.image"
														:alt="speaker.name"
													></v-img>
												</v-list-item-avatar>
											</p>

											<div class="text-center">
												<p class="speaker-title pt-4">
													{{ speaker.job_title }}
												</p>
												<p class="speaker-name mt-n6">
													{{ speaker.name }}
												</p>
												<div
													class="
														speaker-description
														mt-n3
														px-4
													"
													style="
														overflow-y: auto;
														max-height: 300px;
														text-align: justify;
														text-justify: inter-word;
													"
													v-html="speaker.description"
												></div>
											</div>
										</v-card>
									</v-menu>
								</v-col>
							</v-row>
						</div>
					</v-col>
				</v-row>
			</template>
			<!-- No access -->
			<template v-else>
				<v-row style="height: 100%">
					<v-col
						class="d-flex justify-center align-center mt-n16"
						style="height: 100%"
						><h2 class="white--text">
							Due to registration permission settings, you do not
							have access to attend this session.
						</h2></v-col
					>
				</v-row>
			</template>
		</v-container>
	</Page>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import axios from "axios";
export default {
	data() {
		return {
			mini: false,
			miniTwo: true,
			show: false,
			showFirstMenu: true,
			showSecondMenu: true,
			menu: false,
			qaForm: {
				isSuccess: false,
				message: null,
			},
		};
	},
	computed: {
		...mapState(["event", "agendas"]),
		...mapGetters(["currentAgenda", "currentSession", "me", "isAdmin"]),

		computedAgendas() {
			const agendas = this.agendas.filter(
				(agenda) => agenda.event_day === this.event.day
			);

			const currentAgendaIndex = agendas.findIndex(
				(agenda) => agenda.current
			);

			console.log("(Agenda) Current Agenda index:", currentAgendaIndex);

			return agendas.map((agenda, index) => {
				if (index < currentAgendaIndex) {
					console.log(
						"(Agenda)",
						"Agenda:",
						agenda.title,
						"index is:",
						index
					);
					agenda.previous = true;
				}

				const sessions = agenda.sessions;

				if (agenda.current) {
					const currentSessionIndex = sessions.findIndex(
						(session) => session.current
					);

					sessions
						.slice(0, currentSessionIndex)
						.forEach((_, sessionIndex) => {
							sessions[sessionIndex].previous = true;
						});
				}

				agenda.sessions = sessions;

				return agenda;
			});
		},
		agendaMenuValue() {
			if (this.show) return null;

			return this.computedAgendas.findIndex((agenda) => agenda.current);
		},
		hasAccess() {
			if (this.isAdmin) return true;

			const userGroups = this.me?.groups;
			const sessionGroups = this.currentSession?.user_groups;

			if (!sessionGroups || sessionGroups?.length === 0) return true;

			if (userGroups) {
				return sessionGroups.some((sessionGroup) =>
					userGroups.includes(sessionGroup)
				);
			}

			return false;
		},
	},

	methods: {
		async sendQaMessage() {
			if (!this.qaForm.message) return;

			const data = {
				name: this.me.name,
				email: this.me?.email || this.me.username,
				question: this.qaForm.message,
			};
			console.log("Ask a Question submission:", data);
			// Submit

			await axios.post(
				"https://streams.eventcdn.net/id/b5301970-648f-4f59-b2e3-bcb279e91f0e/qa/JUSDkuRRefgy3giAmWNZ4gE7hPEzUlW9jadQVKiYfyo",
				data
			);

			this.qaForm = {
				message: null,
				isSuccess: true,
			};

			// Remove message after 2 secs
			setTimeout(
				() => (this.qaForm = { ...this.qaForm, isSuccess: false }),
				3000
			);
		},
	},
};
</script>

<style lang="scss">
.livehouse-app.v-application {
	.live {
		.v-autocomplete__content.v-menu__content,
		.v-autocomplete__content.v-menu__content .v-list,
		.theme--light.ask-a-question-live.v-text-field--solo
			.v-input__control
			.v-input__slot {
			background: #ffffff 0% 0% no-repeat padding-box !important;
			box-shadow: 0px 0px 0px rgb(92 64 63 / 56%) !important;
			border-radius: 0px !important;
			min-height: auto !important;
			display: flex !important;
			align-items: center !important;
		}

		.v-autocomplete__content.v-menu__content,
		.v-autocomplete__content.v-menu__content .v-list,
		.hide-scroll .v-navigation-drawer__content {
			height: 100%;
			overflow: hidden;
		}

		.v-expansion-panel-content__wrap {
			padding: 0 !important;
		}
		.v-expansion-panel-header {
			cursor: default !important;
			min-height: 32px !important;
		}

		.line-clamp {
			display: -webkit-box;
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;
			overflow: hidden;
		}
	}
}
</style>

