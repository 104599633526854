<template>
  <Page layout="BlankLayout" style="height: 100%; width: 100%">
    <iframe
      title="Inline Done Frame"
      allow="fullscreen"
      style="height: 100%; width: 100%"
      src="https://streams.eventcdn.net/pandoranam/nam-2022-done"
      frameborder="false"
    >
    </iframe>
    <v-container
      class="py-0"
      style="margin-top: -64px"
      :style="{
        'margin-top': $vuetify.breakpoint.mobile ? '-128px' : '-64px',
      }"
    >
      <v-row class="py-0 my-0 mx-0 px-0">
        <v-col cols="12" md="6">
          <a
            href="https://livehouse.com"
            target="_blank"
            rel="noopener"
            class="white--text text-decoration-none body-2"
            style="font-size: 0.75rem !important"
            >Powered by Livehouse / livehouse.com</a
          >
        </v-col>
        <v-col cols="12" md="6">
          <div
            class="d-inline-flex justify-start align-center"
            style="gap: 1rem"
          >
            <v-btn
              v-for="(link, linkIdx) in socialMediaLinks"
              :key="linkIdx"
              icon
              :href="link.href"
              target="_blank"
              rel="noopener"
              color="white"
              ><v-icon color="white">{{ link.icon }}</v-icon></v-btn
            >
          </div>
        </v-col>
      </v-row>
    </v-container>
  </Page>
</template>

<script>
export default {
  data() {
    return {
      socialMediaLinks: [
        {
          icon: "fab fa-tiktok",
          href: "https://www.Tiktok.com/@theofficialpandora",
        },
        {
          icon: "fab fa-pinterest",
          href: "https://www.Pinterest.com/officialpandora/",
        },
        {
          icon: "fab fa-facebook-f",
          href: "https://www.Facebook.com/PandoraUS/",
        },
        {
          icon: "fab fa-instagram",
          href: "https://www.instagram.com/theofficialpandora/?hl=en",
        },
        {
          icon: "fab fa-youtube",
          href: "https://www.youtube.com/user/theofficialpandora",
        },
      ],
    };
  },
};
</script>